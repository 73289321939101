<template>
  <div class="activity-page-detail">
    <main>
      <el-image
        @contextmenu="handleMouse"
        v-if="content.activity_banner"
        :src="content.activity_banner"
        :fit="'cover'"
        class="cover-img"
      ></el-image>
      <div class="activity-content">
        <div class="detail">
          <p class="title">{{ content.second_name }}</p>
          <p class="purpose">{{ content.activity_name }}</p>
          <div class="address">{{ content.host_city }}</div>
          <div class="date">
            {{ formatTime(content.start_time) }}~{{
              formatTime(content.end_time)
            }}
          </div>
        </div>
        <div class="describe" v-if="content.activity_html">
          <h3>{{ type == "1" ? "大赛细则" : "展览相关" }}</h3>
          <div v-html="content.activity_html"></div>
        </div>
        <div class="curator" v-if="content.organizer">
          <h3>{{ type == "1" ? "征集时间" : "策展人" }}</h3>
          <div v-if="type == '1'">
            {{ content.solicitation_start_time }} -
            {{ content.solicitation_end_time }}
          </div>
          <div v-else>
            {{ content.organizer }}
          </div>
        </div>
        <div class="sponsor" v-if="content.sponsor">
          <h3>{{ type == "1" ? "奖项设置" : "赞助与支持" }}</h3>
          <div>{{ content.sponsor }}</div>
        </div>
        <div class="video-data" v-if="content.activity_video_path">
          <h3>视频资料</h3>
          <video
            @contextmenu="handleMouse"
            :src="content.activity_video_path"
            :autoplay="false"
            controls
            loop="loop"
            class="video"
          >
            您的浏览器不支持 video 标签。
          </video>
        </div>
        <div
          class="scene"
          v-if="content.exhibition && content.exhibition.length > 0"
        >
          <h3>展览现场</h3>
          <div class="scene-content">
            <div
              v-for="(item, index) in content.exhibition"
              :key="index"
              class="scene-item"
            >
              <el-image
                @contextmenu="handleMouse"
                :src="item"
                :fit="'cover'"
                class="scene-img"
              ></el-image>
            </div>
          </div>
        </div>
        <div
          class="share"
          v-if="content.shareparty_info && content.shareparty_info.length > 0"
        >
          <h3>分享会</h3>
          <div class="sharing-meeting">
            <div
              v-for="(item, index) in content.shareparty_info"
              :key="index"
              class="sharing-list"
            >
              <el-image
                @contextmenu="handleMouse"
                :src="item.share_image_path"
                :fit="'cover'"
                class="share-img"
              ></el-image>
              <div class="sharing-content">
                <p class="sharer">{{ item.share_name }}</p>
                <p class="tag">{{ item.share_description }}</p>
                <p class="theme">
                  分享主题：<span class="theme-name">{{
                    item.share_theme
                  }}</span>
                </p>
                <p class="date-box">
                  <span class="date-box-date"
                    >日期：{{ formatDate(item.share_date) }}</span
                  ><span
                    >时间：{{ item.share_stime }} - {{ item.share_etime }}</span
                  >
                </p>
                <p>地点：{{ item.share_address }}</p>
                <p class="address-detail">{{ item.share_detial_address }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "index",
  components: {},
  data() {
    return {
      id: "", //活动id
      type: 1, //1摄影大赛2线下
      content: {}
    };
  },
  activated() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    // 调取获取协议详情
    this.handleGetDetail();
  },
  methods: {
    handleMouse(e) {
      e.preventDefault();
    },
    formatTime(time) {
      return dayjs(time).format("YYYY年MM月DD日");
    },
    formatDate(date) {
      return dayjs(date).format("YYYY/MM/DD");
    },
    handleGetDetail() {
      const id = this.id;
      this.$api.getActivityDetail({ id }).then(res => {
        if (res.data.code === 1) {
          this.content = res.data?.data;
          console.log(999, this.content);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.activity-page-detail {
  padding: 0 0px 50px;
  color: #000000;
  .cover-img {
    width: 100%;
    height: 742px;
  }
  .activity-content {
    width: 1280px;
    margin: 0 auto;
    h3 {
      font-family: PingFangSC-Medium;
      font-weight: 500;
      font-size: 27px;
      margin-bottom: 44px;
    }
    .detail {
      font-family: PingFangSC-Regular;
      margin-top: 126px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 40px;
      }
      .purpose {
        font-family: PingFangSC-Medium;
        font-size: 60px;
        margin: 30px 0 103px 0;
      }
      .address {
        font-size: 27px;
        border-left: 2px solid #000000;
        border-right: 2px solid #000000;
        margin-bottom: 80px;
        padding: 0 20px;
      }
      .date {
        font-size: 27px;
      }
    }
    .describe {
      margin-top: 142px;
      line-height: 33px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 20px;
      p {
        margin-bottom: 46px;
      }
    }
    .curator {
      font-size: 20px;
      margin-top: 150px;
    }
    .sponsor {
      font-size: 20px;
      margin-top: 136px;
    }
    .video-data {
      margin-top: 173px;
      .video {
        width: 1280px;
      }
    }
    .scene {
      margin-top: 153px;
      .scene-content {
        margin: 0 -8px;
        .scene-item {
          width: 33.3%;
          margin-bottom: 15px;
          padding: 0 8px;
          display: inline-block;
          .scene-img {
            width: 416px;
            height: 312px;
          }
        }
      }
    }
    .share {
      margin-top: 123px;
      .sharing-meeting {
        .sharing-list {
          width: 50%;
          display: inline-block;
          font-family: PingFangSC-Regular;
          font-size: 17px;
          // line-height: 33px;
          margin-bottom: 65px;
          .share-img {
            width: 634px;
            height: 333px;
          }
          .sharing-content {
            margin-top: 41px;
            border-left: 1px solid #a0a0a0;
            padding-left: 32px;
          }
          .sharer {
            font-family: PingFangSC-Medium;
            font-size: 25px;
          }
          .tag {
            font-family: PingFangSC-Light;
            font-size: 15px;
            padding: 19px 0 20px;
          }
          .date-box {
            padding: 23px 0 18px;
            .date-box-date {
              padding-right: 46px;
            }
          }
          .address-detail {
            padding-top: 18px;
          }
          .theme {
            font-family: PingFangSC-Medium;

            .theme-name {
              font-size: 22px;
            }
          }
          &:nth-child(2n) {
            padding-left: 6px;
          }
        }
      }
    }
  }
}
// 媒体查询 大于等于某个宽度
@media screen and (max-width: 1339px) {
  .activity-page-detail {
    .activity-content {
      width: 1060px;
      .video-data {
        .video {
          width: 1060px;
        }
      }
      .scene {
        .scene-content {
          .scene-item {
            .scene-img {
              width: 342px;
              height: 256px;
            }
          }
        }
      }
      .share {
        .sharing-meeting {
          .sharing-list {
            .share-img {
              width: 524px;
              height: 275px;
            }
          }
        }
      }
    }
  }
}
</style>
